import React, { useEffect, useState,useRef } from 'react';
import { string } from 'yup';
import _ from 'lodash';
import eurekaMgrs from '@eureka/ui-managers';
import moment from 'moment-timezone';
import {
  List,
  ListMode,
  StandardListItem,
  Button,
  ButtonDesign,
  Title,
  TitleLevel,
  MessageStrip,
  MessageStripDesign,
  Toast
} from '@ui5/webcomponents-react';
import {
  useTranslation,
  Spinner,
  getCurrentLanguage,
  setLanguage,
  configManagerSetLanguage,
} from './eureka';
import { closeShellDialog } from '../../plugins/dialog.plugin';
import { fetchMe, patchMe, fetchLanguages } from './axios';
import LanguageAndRegion from './LanguageAndRegion';
import { TimeFormatOptions, TimeFormatTexts, TimeFormatTextsDefualts } from './options';
import UserAppearance from './UserAppearance';
import { setDocumentLang, getThemeById } from '../../../common/Utils';
import UserAccount from './UserAccount';
import { defaultTimezoneKey } from '../../constants/timezone.constants';
import { PRP_124330_Timezone } from '../../constants/featureFlags.constants';
import { editBasicSetupData } from './basicSetup.api';
const { getFeatureToggle, getConfig } = eurekaMgrs.ConfigManager;

const { getThemeId, setThemeId } = eurekaMgrs.AppearanceManager;
const initialData = {
  userName: '',
  valueStateFirstName: 'None',
  valueStateLastName: 'None',
  pictureId: null,
  email: '',
  phone: '',
  language: '',
  dateFormat: '',
  timeFormat: '',
  timeZone: defaultTimezoneKey,
};

const getFullUserName = userName => {
  if (userName && typeof userName === 'object') {
    return userName.familyNameFirst
      ? userName.lastName + ' ' + userName.firstName
      : userName.firstName + ' ' + userName.lastName;
  }
};
const userProfileLanguageRegion = 'user-profile_language_region';
const UserProfileAppearance = 'user-profile_appearance';

export default function UserProfile({ config, eventBus }) {
  const { t } = useTranslation('shell');
  const [isLoading, setLoading] = useState(false);
  const [currentItem, setCurrentItem] = useState(userProfileLanguageRegion);
  const [data, setData] = useState({ ...initialData });
  const [originalData, setOriginalData] = useState({ ...initialData });
  const [languages, setLanguages] = useState([]);
  const [timeFormatIndex, setTimeFormatIndex] = useState(0);
  const [originalTimezoneConfig, setOriginalTimezoneConfig] = useState(
    getConfig('BasicSetup')?.timezoneRegionId,
  );
  const [timezoneConfig, setTimezoneConfig] = useState(getConfig('BasicSetup')?.timezoneRegionId);
  const [changesToBeNotified, setChangesToBeNotified] = useState({
    languageChanged: false,
    timezoneChanged: false,
    themeChanged: false,
    userProfileChanged: false,
    user: {},
    timezoneRegionId: '',
  });
  const [showErrorInUserInputMessage, setShowErrorInUserInputMessage] = useState('');
  const saveSuccessToastRef = useRef();

  const nameValidationSchema = string().matches(/^[a-zA-ZÄÖÜßäöü0-9-_ ]+$/, t('INVALID_USERNAME'));
  const timezoneValidationSchema = string().oneOf(
    moment.tz.names(),
    t('Invalid_Timezone_Region_Id'),
  );

  useEffect(() => {
    setTimeFormatIndex(TimeFormatOptions.indexOf(data.timeFormat));
  }, [data.timeFormat]);

  const handleErrorMessageStripClose = () => {
    setShowErrorInUserInputMessage('');
  };

  useEffect(() => {
    const getData = () => {
      setLoading(true);
      return Promise.all([fetchMe(), fetchLanguages(getCurrentLanguage())])
        .then(results => {
          const me = results[0].data;
          setLanguage(me.language);
          setThemeId(me.themeId);
          setData({
            ...me,
            valueStateFirstName: 'None',
            valueStateLastName: 'None',
          });
          setOriginalData({
            ...me,
            valueStateFirstName: 'None',
            valueStateLastName: 'None',
          });
          setLanguages(results[1].data || []);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    getData();
  }, []);

/* istanbul ignore next */
  const notifyChanges = ({
    languageChanged,
    themeChanged,
    timezoneChanged,
    userProfileChanged,
    user,
    timezoneRegionId
  }) => {
    try {
      if (languageChanged) {
        setLanguage(user?.language);
        configManagerSetLanguage(user?.language);
        eventBus?.emit('i18n-update', '', user?.language || 'en-US');
        document.lang = user?.language || 'en-US';
        document.documentElement.setAttribute('lang', user?.language || 'en-US');
        window.location.reload();
      }
      if (themeChanged) {
        const themeId = user?.themeId || 'sap_horizon';
        eventBus?.emit('appearance-update', 'user theme', { themeChanged: true, themeId });
      }
      if (userProfileChanged) {
        eventBus?.emit('configuration-updated', 'user profile', {
          key: 'userProfile',
          data: user,
        });
      }
      if (timezoneChanged) {
        eventBus?.emit('configuration-updated', 'basic setup', {
          key: 'basicSetup',
          data: { ...getConfig('BasicSetup'), timezoneRegionId: timezoneRegionId },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleCancel = () => {
    closeShellDialog();
    setShowErrorInUserInputMessage('');
    notifyChanges({ ...changesToBeNotified });
    setData({
      ...originalData,
    });
    setTimezoneConfig(originalTimezoneConfig);
  };

  /* istanbul ignore next */
  const onDataPropChange = (value, propName) => {
    if (propName === 'timezoneRegionId') {
      setTimezoneConfig(value);
    } else if (propName.indexOf('.') > 0) {
      const [objName, subPropName] = propName.split('.');
      setData(prevData => {
        return {
          ...prevData,
          [objName]: {
            ...data[objName],
            [subPropName]: value,
          },
        };
      });
    } else {
      setData(prevData => {
        return {
          ...prevData,
          [propName]: value,
        };
      });
    }
  };

  /* istanbul ignore next */
  // TODO: Add test cases for save
  const handleSave = () => {
    try {
      setLoading(true);
      nameValidationSchema.validateSync(data.userName.firstName + ' ' + data.userName.lastName);
      if (getFeatureToggle(PRP_124330_Timezone)) {
        timezoneValidationSchema.validateSync(timezoneConfig);
      }

      const updatedUserConfig = {};
      let languageChanged = false;
      let themeChanged = false;
      Object.keys(data).forEach(key => {
        if (originalData[key] !== data[key]) {
          updatedUserConfig[key] = data[key];
          if (key === 'language') {
            languageChanged = true;
          }
          if (key === 'themeId') {
            themeChanged = true;
          }
        }
      });
      if (Object.keys(updatedUserConfig).length > 0) {
        let current = parseInt(data.userUpdateState);
        current = isNaN(current) ? 1 : current;
        updatedUserConfig.userUpdateState = current + 1;
      }
      const timezoneChanged =
        PRP_124330_Timezone && timezoneConfig !== originalTimezoneConfig;

      const userConfigUpdatePromise = () =>
        patchMe({ ...updatedUserConfig })
          .then(result => {
            const user = result.data;
            setOriginalData(_.cloneDeep(user));
            setData(_.cloneDeep(user));

            return Promise.resolve({
              languageChanged: changesToBeNotified.languageChanged || languageChanged,
              themeChanged: changesToBeNotified.themeChanged || themeChanged,
              userProfileChanged: true,
              user: _.cloneDeep(user),
            });
          })
          .catch(e => {
            setData(_.cloneDeep(originalData));
            return Promise.reject(e);
          });

      const timezoneUpdatePromise = () =>
        editBasicSetupData({ userId: getConfig('CurrentUser')?.id, timeZone: timezoneConfig })
          .then(() => {
            setOriginalTimezoneConfig(timezoneConfig);
            return Promise.resolve({
              timezoneChanged: true,
              timezoneRegionId: timezoneConfig,
            });
          })
          .catch(e => {
            setTimezoneConfig(originalTimezoneConfig);
            return Promise.reject(e);
          });

      let updatePromises = [];
      if (Object.keys(updatedUserConfig).length > 0) {
        updatePromises.push(userConfigUpdatePromise());
      }
      if (timezoneChanged) {
        updatePromises.push(timezoneUpdatePromise());
      }

      if (updatePromises.length > 0) {
        Promise.allSettled(updatePromises).then(results => {
          setLoading(false);
          const fulfilledPromises = results.filter(result => result.status === 'fulfilled');
          const fulfilledPromiseValues =
            fulfilledPromises.length > 0
              ? fulfilledPromises
                  .map(r => r.value)
                  .reduce((acc, val) => {
                    return { ...acc, ...val };
                  })
              : {};
          setChangesToBeNotified({
            ...changesToBeNotified,
            ...fulfilledPromiseValues,
          });
          if (fulfilledPromises.length === updatePromises.length) {
            saveSuccessToastRef.current.show();
            closeShellDialog();
            notifyChanges({
              ...changesToBeNotified,
              ...fulfilledPromiseValues,
            });
          } else if (fulfilledPromises.length === 0) {
            setShowErrorInUserInputMessage(t('UserProfile_Msg_UpdateFailed'));
          } else {
            setShowErrorInUserInputMessage(t('UserProfile_Msg_UpdatePartialFailed'));
          }
        });
      }
      else{
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (error.message) {
        setShowErrorInUserInputMessage(error.message);
      }
    }
  };

  return (
    <div>
      <Title level={TitleLevel.H3} style={{ padding: '16px 24px', fontSize: '16px' }}>
        {t('UserProfile_ProfileSettings', 'Settings', { type: 'tit', desc: '' })}
      </Title>
      <div style={{ borderBottom: '1px solid #e8e8e8', borderTop: '1px solid #e8e8e8' }}>
        <div
          style={{
            display: 'flex',
            width: '636px',
            height: '492px',
            minHeight: '330px',
            padding: 0,
          }}
        >
          <List
            style={{ width: '219px' }}
            mode={ListMode.SingleSelect}
            noDataText={t('UserProfile_Msg_NoDataAvailable', 'No data available', {
              type: 'msg',
              desc: '',
            })}
            onItemClick={evt => {
              setCurrentItem(evt.detail.item.id);
            }}
          >
            <StandardListItem
              id="user-profile_account"
              icon="employee"
              selected={currentItem === 'user-profile_account'}
              description={getFullUserName(data.userName)}
            >
              {t('UserProfile_UserAccount')}
            </StandardListItem>
            <StandardListItem
              icon="globe"
              id={userProfileLanguageRegion}
              selected={currentItem === userProfileLanguageRegion}
              description={`${(
                (data.language && data.language.split('-')[0]) ||
                '...'
              ).toUpperCase()} | ${t(
                TimeFormatTexts[timeFormatIndex] || '...',
                TimeFormatTextsDefualts[timeFormatIndex],
                { type: 'tit', desc: '' },
              ) || '...'}`}
            >
              {t('UserProfile_LanguageAndRegion', 'Language and Region', {
                type: 'mit',
                desc: '',
              })}
            </StandardListItem>
            <StandardListItem
              icon="palette"
              id={UserProfileAppearance}
              selected={currentItem === UserProfileAppearance}
              // description={`${(getThemeId() || '...').toUpperCase()}`}
              description={getThemeById(getThemeId())?.displayName}
            >
              {t('UserProfile_Appearance', 'Appearance', { type: 'mit', desc: '' })}
            </StandardListItem>
          </List>
          <section
            style={{
              flexGrow: 1,
              width: '460px',
              padding: '1rem',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              borderLeft: '1px solid #e8e8e8',
            }}
          >
            {currentItem === 'user-profile_account' && (
              <UserAccount
                data={data}
                timezoneConfig={timezoneConfig}
                onDataPropChange={onDataPropChange}
              />
            )}
            {currentItem === userProfileLanguageRegion && (
              <LanguageAndRegion
                data={data}
                languages={languages}
                onDataPropChange={onDataPropChange}
              />
            )}
            {currentItem === UserProfileAppearance && (
              <UserAppearance data={data} theme={languages} onDataPropChange={onDataPropChange} />
            )}
          </section>
        </div>
      </div>
      {showErrorInUserInputMessage && (
        <MessageStrip
          data-testid="errorMessageStrip"
          design={MessageStripDesign.Negative}
          onClose={handleErrorMessageStripClose}
          hideIcon={false}
        >
          {showErrorInUserInputMessage}
        </MessageStrip>
      )}
       <Toast
        ref={saveSuccessToastRef}
        duration={5000}
      >
       {t('UserProfile_Msg_UpdateSuccess')}
      </Toast>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          height: '60px',
        }}
      >
        <Button
          disabled={
            JSON.stringify(data) === JSON.stringify(originalData) &&
            timezoneConfig === originalTimezoneConfig
          }
          design={ButtonDesign.Emphasized}
          onClick={handleSave}
          data-testid="saveButton"
        >
          {t('Save', 'Save', { type: 'but', desc: '' })}
        </Button>
        <Button
          style={{
            marginLeft: '1rem',
            marginRight: '0.5rem',
          }}
          onClick={handleCancel}
          data-testid="cancelButton"
        >
          {t('Cancel', 'Cancel', { type: 'but', desc: '' })}
        </Button>
      </div>

      {isLoading && (
        <div
          style={{
            top: 0,
            width: '100%',
            height: '100%',
            position: 'absolute',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            background: 'rgba(0, 0, 0, 0.01)',
            zIndex: 999,
          }}
        >
          <Spinner />
        </div>
      )}
    </div>
  );
}
