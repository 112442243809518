import React from 'react';
import moment from 'moment-timezone';
import eurekaMgrs from '@eureka/ui-managers';
const { getFeatureToggle } = eurekaMgrs.ConfigManager;
import { string } from 'yup';
import {
  Avatar,
  Input,
  Label,
  AvatarShape,
  FormGroup,
  FormItem,
  Select,
  Option,
  Text,
  ComboBox,
  ComboBoxItem,
} from '@ui5/webcomponents-react';
import { useTranslation, UtcMapping } from './eureka';
import { defaultTimezoneKey } from '../../constants/timezone.constants';
import { PRP_124330_Timezone } from '../../constants/featureFlags.constants';

const { ConfigManager } = eurekaMgrs;
const { getConfig } = ConfigManager;

const TimeZoneOptions = Object.keys(UtcMapping).map(key => {
  return { ...UtcMapping[key], key };
});

const LabelStyle = {
  width: '120px',
  marginTop: '4px',
  alignItems: 'center',
};
const FormItemStyle = {
  marginTop: '10px',
};
const AvatarContainer = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '1rem',
};

export default function UserAccount({ data, timezoneConfig, onDataPropChange }) {
  const { t } = useTranslation('shell');
  const avatar = data?.avatarIcon || {};
  const type = avatar.type?.toLocaleLowerCase();
  const value = avatar.value;
  const { auth_provider } = getConfig('user') || { auth_provider: '' };

  const timezoneRegionIds = moment.tz.names();

  const nameValidationSchema = string().matches(
    /^[a-zA-ZÄÖÜßäöü00-9-_ ]+$/,
    t('Only_alphabets,_digits,<space>,underscore,ampersand _and_hyphen_are_allowed_for_this_field'),
  );

  const ValueStateMessage = props => {
    return (
      <div data-testid="validation_message" slot={props.slot}>
        {t(
          'Only_alphabets,_digits,<space>,underscore,ampersand _and_hyphen_are_allowed_for_this_field',
        )}
      </div>
    );
  };
  return (
    <div style={{ height: '100%', width: '80%' }}>
      <div style={{ ...AvatarContainer }}>
        <Avatar
          initials={type === 'initials' ? value : ''}
          image={type === 'image' ? value : null}
          type={type}
          icon={type === 'genderneutralicon' ? 'employee' : ''}
          shape={AvatarShape.Circle}
          size="XL"
          customDisplaySize={'3rem'}
          customFontSize={'1.125rem'}
        />
      </div>

      {data.familyNameFirst ? (
        <>
          <FormItem style={{ ...FormItemStyle }} isHorizontal>
            <Label style={{ ...LabelStyle }}>
              {t('UserProfile_FamilyName', 'Family Name', { type: 'fld', desc: '' })}:
            </Label>
            <Input
              value={data.userName?.lastName || ''}
              disabled={false}
              valueState={data.valueStateLastName}
              valueStateMessage={<ValueStateMessage />}
              onChange={evt => {
                try {
                  nameValidationSchema.validateSync(evt.target.value);
                  onDataPropChange('None', 'valueStateLastName');
                } catch (error) {
                  onDataPropChange('Error', 'valueStateLastName');
                } finally {
                  onDataPropChange(evt.target.value, 'userName.lastName');
                }
              }}
              data-testid="lastName"
            />
          </FormItem>
          <FormItem style={{ ...FormItemStyle }} isHorizontal>
            <Label style={{ ...LabelStyle }}>
              {t('UserProfile_FirstName', 'First Name', { type: 'fld', desc: '' })}:
            </Label>
            <Input
              value={data.userName?.firstName || ''}
              disabled={false}
              valueStateMessage={<ValueStateMessage />}
              valueState={data.valueStateFirstName}
              onChange={evt => {
                try {
                  nameValidationSchema.validateSync(evt.target.value);
                  onDataPropChange('None', 'valueStateFirstName');
                } catch (error) {
                  onDataPropChange('Error', 'valueStateFirstName');
                } finally {
                  onDataPropChange(evt.target.value, 'userName.firstName');
                }
              }}
              data-testid="firstName"
            />
          </FormItem>
        </>
      ) : (
        <>
          <FormItem style={{ ...FormItemStyle }} isHorizontal>
            <Label style={{ ...LabelStyle }}>
              {t('UserProfile_FirstName', 'First Name', { type: 'fld', desc: '' })}:
            </Label>
            <Input
              value={data.userName?.firstName || ''}
              disabled={false}
              valueStateMessage={<ValueStateMessage />}
              valueState={data.valueStateFirstName}
              onChange={evt => {
                try {
                  nameValidationSchema.validateSync(evt.target.value);
                  onDataPropChange('None', 'valueStateFirstName');
                } catch (error) {
                  onDataPropChange('Error', 'valueStateFirstName');
                } finally {
                  onDataPropChange(evt.target.value, 'userName.firstName');
                }
              }}
              data-testid="firstName"
            />
          </FormItem>
          <FormItem style={{ ...FormItemStyle }} isHorizontal>
            <Label style={{ ...LabelStyle }}>
              {t('UserProfile_FamilyName', 'Family Name', { type: 'fld', desc: '' })}:
            </Label>
            <Input
              value={data.userName?.lastName || ''}
              disabled={false}
              valueStateMessage={<ValueStateMessage />}
              valueState={data.valueStateLastName}
              onChange={evt => {
                try {
                  nameValidationSchema.validateSync(evt.target.value);
                  onDataPropChange('None', 'valueStateLastName');
                } catch (error) {
                  onDataPropChange('Error', 'valueStateLastName');
                } finally {
                  onDataPropChange(evt.target.value, 'userName.lastName');
                }
              }}
              data-testid="lastName"
            />
          </FormItem>
        </>
      )}
      <FormItem style={{ ...FormItemStyle }} isHorizontal>
        <Label style={{ ...LabelStyle }}>
          {t('UserProfile_ID', 'ID', { type: 'fld', desc: '' })}:
        </Label>
        <Input readonly={true} value={data.s4ExternalUserId || ''} data-testid="externalUserId" />
      </FormItem>

      <FormItem style={{ ...FormItemStyle }} isHorizontal>
        <Label style={{ ...LabelStyle }}>
          {t('UserProfile_Email', 'Email', { type: 'fld', desc: '' })}:
        </Label>
        <Input
          disabled={true}
          value={data.email || ''}
          onChange={evt => {
            onDataPropChange(evt.target.value, 'email');
          }}
          data-testid="email"
        />
      </FormItem>

      <FormItem style={{ ...FormItemStyle }} isHorizontal>
        <Label style={{ ...LabelStyle }}>
          {t('UserProfile_TimeZone', 'TimeZone', { type: 'fld', desc: '' })}:
        </Label>
        {getFeatureToggle(PRP_124330_Timezone) ? (
          <ComboBox
            style={{ width: '210px' }}
            onChange={evt => {
             onDataPropChange(evt.target.value, 'timezoneRegionId');
             console.log(evt.target.value);
            }}
            data-testid="timeZoneRegionId-ComboBox"
            filter="Contains"
            value={timezoneConfig}
          >
            {timezoneRegionIds.map(str => {
              return (
                <ComboBoxItem key={str} data-testid={`timeZone-${str}`} data-id={str} text={str} />
              );
            })}
          </ComboBox>
        ) : (
          <Select
            style={{ width: '210px' }}
            onChange={evt => {
              onDataPropChange(evt.detail.selectedOption.dataset.id, 'timeZone');
            }}
            data-testid="timeZone"
          >
            {TimeZoneOptions.map((timeZone, index) => {
              return (
                <Option
                  key={timeZone.key}
                  data-id={timeZone.key}
                  value={timeZone.key}
                  selected={
                    data.timeZone === null
                      ? timeZone.key === defaultTimezoneKey
                      : data.timeZone === timeZone.key
                  }
                >
                  {timeZone.description}
                </Option>
              );
            })}
          </Select>
        )}
      </FormItem>
      <FormItem style={{ ...FormItemStyle }} isHorizontal>
        <Label style={{ ...LabelStyle, marginTop: '10px' }}>
          {t('UserProfile_subdomain', 'Subdomain Name', { type: 'fld', desc: '' })}:
        </Label>
        <Text data-testid="subdomain" style={{ ...LabelStyle, marginTop: '10px' }}>
          {auth_provider}
        </Text>
      </FormItem>
    </div>
  );
}
