export const searchInfo = {
  callback: () => {
    //TODO
  },
  glyph: 'search',
  label: 'Search',
  onSearch: () => {
    //TODO
  },
  placeholder: 'Enter a term',
  searchList: [],
};
