import React, { useEffect, useRef } from 'react';
import { Avatar } from '@ui5/webcomponents-react';
export default (props) => {
  const avatarRef = useRef();
  useEffect(() => {
    let domRef = avatarRef.current;
    if (props.type === 'image' && domRef?.getDomRef()) {
      domRef.getDomRef().children[0].style.backgroundImage = `url("${props.value}")`;
      domRef.getDomRef().children[0].style.backgroundColor = 'var(--ui5-avatar-accent6)';
    }
    return () => {
      domRef = null;
    };
  }, []);
  return <Avatar ref={avatarRef} {...props} />;
};
