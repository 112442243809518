export const COMMON_SHOW_MENU = 'COMMON_SHOW_MENU';
export const COMMON_HIDE_MENU = 'COMMON_HIDE_MENU';

export const FETCH_CONFIG_BEGIN = 'FETCH_CONFIG_BEGIN';
export const FETCH_CONFIG_SUCCESS = 'FETCH_CONFIG_SUCCESS';
export const FETCH_CONFIG_FAILURE = 'FETCH_CONFIG_FAILURE';
export const FETCH_CONFIG_DISMISS_ERROR = 'FETCH_CONFIG_DISMISS_ERROR';

export const FETCH_USER_PERMISSION_BEGIN = 'FETCH_USER_PERMISSION_BEGIN';
export const FETCH_USER_PERMISSION_SUCCESS = 'FETCH_USER_PERMISSION_SUCCESS';
export const FETCH_USER_PERMISSION_FAILURE = 'FETCH_USER_PERMISSION_FAILURE';
export const FETCH_USER_PERMISSION_DISMISS_ERROR = 'FETCH_USER_PERMISSION_DISMISS_ERROR';
