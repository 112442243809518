import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import { MessageStrip } from '@ui5/webcomponents-react';
import { Header, SidePanel } from '../common';
import WelcomeMessage from './WelcomeMessage';
import { ShellDialog } from '../plugins/dialog.plugin';
import MessageToast from '../plugins/toast.plugin';
import EurekaComponents from 'eureka';
import eurekaMgrs from '@eureka/ui-managers';

const { getConfig } = eurekaMgrs.ConfigManager;
const { Spinner } = EurekaComponents.components;
const { OptionalMessageBox } = EurekaComponents.controls;

// const { i18n, getCurrentLanguage, withTranslation } = EurekaComponents.I18nProvider;

const checkPermissions = (item, currentUserPermissions) => {
  // eslint-disable-next-line no-prototype-builtins
  if (item.hasOwnProperty('skipPermission') && item.skipPermission === 'true') {
    return true;
  }

  if (item.permissions) {
    const permissions = Array.isArray(item.permissions)
      ? item.permissions.map((p) => p.toUpperCase())
      : [];
    if (item.conjunction && item.conjunction === 'OR') {
      return permissions.length > 0 && permissions.some((p) => currentUserPermissions.includes(p));
    }

    return permissions.length > 0 && permissions.every((p) => currentUserPermissions.includes(p));
  }
  return false;
};

export class WelcomePage extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
    eventBus: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const permissions = getConfig('CurrentUserPermissions') || [];

    if (
      permissions?.length > 0 &&
      ['MANAGEORDERPROPOSAL.READ'].every((p) => permissions.includes(p)) &&
      this.props.config.components &&
      (this.props.config.components || []).filter((c) => c.name === 'crp-manage-order-proposal-ui')
        .length > 0
    ) {
      this.props.history.push('/manage-order-proposal');
    } else if (permissions?.length > 0 && this.props.config && this.props.config.components) {
      let listitems = [];
      this.props.config.components.forEach((comp) => {
        const { sidenav } = comp.config;
        if (sidenav && Array.isArray(sidenav) && sidenav.length > 0) {
          listitems = listitems.concat(sidenav);
        }
      });

      listitems = listitems
        .filter(
          (i) =>
            i.items?.filter((c) => checkPermissions(c, permissions)).length > 0 ||
            checkPermissions(i, permissions),
        )
        .sort((a, b) => a.order - b.order);

      if (listitems.length > 0) {
        if (
          listitems[0].items &&
          Array.isArray(listitems[0].items) &&
          listitems[0].items.length > 0
        ) {
          const subitem = listitems[0].items.find((i) => checkPermissions(i, permissions));
          this.props.history.push(subitem.router);
        } else {
          this.props.history.push(listitems[0].router);
        }
      }
    }
  }

  renderError(err) {
    return (
      <MessageStrip className="" slot="" style={{}} tooltip="">
        {err}
      </MessageStrip>
    );
  }

  render() {
    return (
      <div className="fd-shell fd-shell--fundamentals">
        <div className="fd-shell__header">
          <Suspense fallback={<Spinner />}>
            <Header
              history={this.props.history}
              config={this.props.config}
              settings={this.props.settings}
              eventBus={this.props.eventBus}
              user={this.props.user}
            />
          </Suspense>
        </div>
        <div className="fd-shell__app">
          <div className="fd-app">
            <main className="fd-app__main">
              <Suspense fallback={<Spinner cssClass="page-load-spinner" />}>
                <div className="home-page">
                  <WelcomeMessage />
                </div>
              </Suspense>
            </main>
          </div>
        </div>
        <ShellDialog key="welcome-page-dialog" />
        <MessageToast key="welcome-page-toast" />
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    home: state.home,
    common: state.common,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

// export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(WelcomePage));
export default connect(mapStateToProps, mapDispatchToProps)(WelcomePage);
