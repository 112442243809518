import {
  PRP_8542_Store_Manage_Config_Profile,
  PRP_3026_Store_Group,
  PRP_3160_Store_ConfigAssign_ProdLoc,
  PRP_2348_Enable_Supplier_Minimum,
} from './featureFlags.constants';

export const hiddenSideNavElements = [
  {
    componentName: 'storeconfigurationprofiles',
    featureFlag: PRP_8542_Store_Manage_Config_Profile,
  },

  {
    componentName: 'storegroup',
    featureFlag: PRP_3026_Store_Group,
  },
  {
    componentName: 'configurationassignmentsstores',
    featureFlag: PRP_3160_Store_ConfigAssign_ProdLoc,
  },
  {
    componentName: 'suppliergroup',
    featureFlag: PRP_2348_Enable_Supplier_Minimum,
  },
];
