import eureka from '@eureka/ui-components/index.esm.js'
const { axiosCreate } = eureka.HttpWrapper;
const userPreferenceAxios = axiosCreate({
  baseURL: '/api/user-configuration/business/v1/preferences/me',
}).instance;

function getAppPreference(appName) {
  return userPreferenceAxios.get(`/${appName}`);
}

function saveUserPreference(appName, preferenceKey, preference) {
  return userPreferenceAxios.post(`/${appName}/${preferenceKey}`, preference)
}

function updateUserPreference(appName, preferenceKey, preference) {
  return userPreferenceAxios.patch(`/${appName}/${preferenceKey}`, preference)
}

export {
  getAppPreference,
  saveUserPreference,
  updateUserPreference
};