import { axiosCreate } from './eureka';

export const usersAxios = axiosCreate({
  baseURL: '/api/user-configuration-plus/business/v1/users',
}).instance;

export const fetchMe = async () => usersAxios.get('/me');

export const patchMe = async (payload) => usersAxios.patch('/me', payload);

export const fetchLanguages = async (langCode) => {
  const headers = {
    'Accept-Language': langCode,
    // 'X-Tenant-ID': '5464198398218240',
    // 'X-User-ID': 1,
    // 'Content-Type': 'application/json',
  };
  const languagesAxios = axiosCreate({
    headers,
    baseURL: '/api/crp-generic-biz-config/business/v1/logon-languages',
  }).instance;
  return languagesAxios.get();
};
