import React from 'react';
import { Select, Option, Label, SegmentedButtonItem } from '@ui5/webcomponents-react';
import { SegmentedButton, ToggleButton } from '@ui5/webcomponents-react';
import {
  DateFormatOptions,
  DateFormatTexts,
  TimeFormatOptions,
  TimeFormatTexts,
  TimeFormatTextsDefualts,
} from './options';
import { useTranslation } from './eureka';

const formItemStyle = {
  width: '100%',
  margin: '15px auto',
  display: 'flex',
  flexDirection: 'column',
};

const labelStyle = {
  width: '100%',
  marginBottom: '4px',
};

export default ({ data, languages, onDataPropChange }) => {
  const { t } = useTranslation('shell');

  return (
    <div style={{ height: '60%', width: '80%', padding: '10px 22px' }}>
      <div style={{ ...formItemStyle }}>
        <Label style={{ ...labelStyle }}>
          {t('UserProfile_Language', 'Language', { type: 'tit', desc: '' })}:
        </Label>
        <Select
          onChange={(evt) => {
            const lng = evt.detail.selectedOption.dataset.id;
            /* istanbul ignore next */
            onDataPropChange(lng, 'language');
          }}
          id="language"
          data-testid="select-language"
          style={{ width: '100%' }}
        >
          {languages.map((language) => {
            return (
              <Option
                key={language.displayLanguageCode}
                data-id={language.displayLanguageCode}
                value={language.displayLanguageCode}
                selected={language.displayLanguageCode === data.language}
              >
                {language.language}
              </Option>
            );
          })}
        </Select>
      </div>
      <div style={{ ...formItemStyle }}>
        <Label style={{ ...labelStyle }}>
          {t('UserProfile_DateFormat', 'Date Format', { type: 'tit', desc: '' })}:
        </Label>
        <Select
          value={data.dateFormat}
          onChange={(evt) => {
            /* istanbul ignore next */
            onDataPropChange(evt.detail.selectedOption.dataset.id, 'dateFormat');
          }}
          id="dateFormat"
          style={{ width: '100%' }}
        >
          {DateFormatOptions.map((dateFormat, index) => {
            return (
              <Option
                key={dateFormat}
                data-id={dateFormat}
                value={dateFormat}
                selected={dateFormat === data.dateFormat}
              >
                {DateFormatTexts[index]}
              </Option>
            );
          })}
        </Select>
      </div>
      <div style={{ ...formItemStyle }}>
        <Label style={{ ...labelStyle }}>
          {t('UserProfile_TimeFormat', 'Time Format', { type: 'tit', desc: '' })}:
        </Label>
        <SegmentedButton
          data-testid={`time-format-selection-buttons`}
          disabled={false}
          onSelectionChange={(evt) => {
            /* istanbul ignore next */
            onDataPropChange(evt.detail.selectedItem.id, 'timeFormat');
          }}
          selectedKey={data.timeFormat}
        >
          {TimeFormatOptions.map((timeFormat, index) => (
            <SegmentedButtonItem
              key={timeFormat}
              id={timeFormat}
              pressed={timeFormat === data.timeFormat}
              data-testid={`time-format-${timeFormat}`}
            >
              {t(TimeFormatTexts[index], TimeFormatTextsDefualts[index], {
                type: 'but',
                desc: '',
              })}
            </SegmentedButtonItem>
          ))}
        </SegmentedButton>
      </div>
    </div>
  );
};
