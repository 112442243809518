// @ts-ignore
__webpack_public_path__ = window.__shell_webpack_public_path__ || '/';
/* eslint-disable react/jsx-filename-extension */
import { setCustomElementsScopingSuffix } from '@ui5/webcomponents-base/dist/CustomElementsScope';
import eurekaMgrs from '@eureka/ui-managers';
import eureka from 'eureka';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import axiosWrapper from './axios/axiosWrapper';
import './styles/index.less';
import { THEME_LIST } from './common/Utils';
import { setTheme } from '@ui5/webcomponents-base/dist/config/Theme.js';
const { setLanguage } = eureka.I18nProvider;
const { getThemeId, setThemeId, getDefaultThemeId, setDefaultThemeId, setThemeList } =
  eurekaMgrs.AppearanceManager;
import { setLanguage as setUi5Language } from '@ui5/webcomponents-base/dist/config/Language.js';

eurekaMgrs.ConfigManager.addConfig('ENABLE_THEME_SETTING', true);
const themeId =
  new URLSearchParams(window.location.search).get('sap-ui-theme') || getDefaultThemeId();
setThemeList(THEME_LIST);
setDefaultThemeId('sap_horizon');
setThemeId(themeId);
setTheme(getThemeId()); // such as sap_fiori_3, sap_belize, sap_horizon

eventBus.on('i18n-update', (langCode) => {
  setLanguage(langCode);
  setUi5Language(eureka.I18nProvider.getUi5Language(langCode));
});

eventBus.on('appearance-update', (settings) => {
  console.log(`Shell UI: appearance settings is ${JSON.stringify(settings)}`);
  const { themeChanged, themeId } = settings;
  if (themeChanged) {
    setThemeId(themeId);
    setTheme(getThemeId());
  }
});
/* istanbul ignore next */
if (process.env.NODE_ENV !== 'test') {
  setCustomElementsScopingSuffix(process.env.APP_NAME);
}

ReactDOM.render(<App {...axiosWrapper()} />, document.getElementById('crp-app'));
