const initialState = {
  showMenu: true,
  userPermissions: [],
  needReloadUserPermissions: true,
  fetchUserPermissionsPending: false,
  fetchUserPermissionsError: null,
  config: null,
  needReloadConfig: true,
  fetchConfigPending: false,
  fetchConfigError: null,
};

export default initialState;
