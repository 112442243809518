import eurekaMgrs from '@eureka/ui-managers';
import { MessageStrip,MessageStripDesign,Toast,ToastPlacement } from '@ui5/webcomponents-react';
import EurekaComponents from 'eureka';
import React, { useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';

const { OptionalMessageBox } = EurekaComponents.controls;

const { useTranslation } = EurekaComponents.I18nProvider;
const useStyles = createUseStyles(({ parameters }) => ({
  toast: {
    '&::shadow': {},
  },
}));
const eventBus = eurekaMgrs.eventBus;
const MessageStripDesignEnum = {
  success: MessageStripDesign.Positive,
  warn: MessageStripDesign.Warning,
  warning: MessageStripDesign.Warning,
  info: MessageStripDesign.Information,
  error: MessageStripDesign.Negative,
  default: MessageStripDesign.Information,
};

const ToastPlugin = () => {
  const { t } = useTranslation();
  const toastRef = useRef();
  const classes = useStyles();
  const [toastProps, setToastProps] = useState({
    toastType: 'default',
    toastDuration: 3000,
    toastContent: '',
    toastPlacement: ToastPlacement.BottomCenter,
    toastIcon: null,
    iconContainerStyles: {},
    iconStyles: {},
  });
  const messageHandler = (message) => {
    /* istanbul ignore next */
    if (message.content) {
      setToastProps({
        toastType: message.type,
        toastContent: message.content,
        toastDuration: message.duration,
        toastPlacement: message.placement || ToastPlacement.BottomCenter,
        messageStripDesign: MessageStripDesignEnum[message.type],
        noCloseButton: message.noCloseButton,
      });
    }
  };
  useEffect(() => {
    eventBus.on('on-message-toast', messageHandler);
    return () => {
      eventBus.detach('on-message-toast', messageHandler);
    };
  }, []);
  /* istanbul ignore next */
  useEffect(() => {
    let current = toastRef && toastRef.current;
    if (
      current &&
      toastProps.toastContent &&
      toastProps.toastContent !== '' &&
      current.getDomRef()
    ) {
      current.show();
      current.getDomRef().style.padding = 0;
      current.getDomRef().style.boxShadow = 'none';
      current.getDomRef().style.maxWidth = 'none';
    }
    return () => {
      current = null;
    };
  }, [toastProps]);
  const onToastClose = React.useCallback(() => {
    toastRef?.current.removeAttribute('open');
  }, [toastRef]);
  let className = ' eureka-message-toast-layout ';
  className += 'eureka-message-toast-type-' + toastProps.toastType;
  return (
    <Toast
      duration={toastProps.toastDuration}
      placement={toastProps.toastPlacement}
      ref={toastRef}
      className={classes.toast}
    >
      <div className={className} style={{ display: 'flex' }}>
        <MessageStrip
          noCloseButton={toastProps.noCloseButton}
          noIcon={false}
          design={toastProps.messageStripDesign}
          onClose={onToastClose}
        >
          {t(toastProps.toastContent)}
        </MessageStrip>
      </div>
    </Toast>
  );
};

export default ToastPlugin;
