import React, { forwardRef } from 'react';
import eureka from 'eureka';
import { Dialog, ToolbarSpacer, Button, Title } from '@ui5/webcomponents-react';

const { useTranslation } = eureka.I18nProvider;

const FeedbackDialog = forwardRef(({
  currentContextData,
  onClickYesHandler,
  onClickNotnowHandler,
}, ref) => {
  const { t } = useTranslation();
  return (
    <Dialog
      ref={ref}
      headerText={t('Your_Opinion_Matters')}
      footer={
        <>
          <ToolbarSpacer />
          <Button
            style={{ margin: '4px' }}
            design="Emphasized"
            onClick={(e) => {
                onClickYesHandler(currentContextData, ref);
            }}
          >
            {t('Yes')}
          </Button>
          <Button
            style={{ margin: '4px' }}
            onClick={(e) => {
                onClickNotnowHandler(currentContextData, ref);
            }}
          >
            {t('Not_Now')}
          </Button>
        </>
      }
    >
      <Title level="H6" style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>
        {t('Can_you_help_us_make_SAP_Predictive_Replenishment_better_with_your_feedback')}
      </Title>
    </Dialog>
  );
});

export default FeedbackDialog;
