/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable operator-linebreak */
/* eslint-disable no-plusplus */
/* eslint-disable no-else-return */
/* eslint-disable import/order */
/* eslint-disable react/jsx-filename-extension */
import eurekaMgrs from '@eureka/ui-managers';
import '@ui5/webcomponents-icons/dist/activity-individual';
import '@ui5/webcomponents-icons/dist/documents';
import '@ui5/webcomponents-icons/dist/home';
import '@ui5/webcomponents-icons/dist/json-imports/Icons.js';
import '@ui5/webcomponents-icons/dist/kpi-corporate-performance';
import '@ui5/webcomponents-icons/dist/settings';
import '@ui5/webcomponents-icons/dist/visits';
import { SideNavigationItem,SideNavigationSubItem } from '@ui5/webcomponents-react';
import eureka from 'eureka';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getURLParam, removeFeatureFlaggedComponent } from '../../common/Utils';
import * as actions from './redux/actions';
import SideNavigationObserver from './SideNavigationObserver';
import { hiddenSideNavElements } from '../constants/hiddenSideNavElements.constants';
import { List, Text, StandardListItem, FlexBox, Icon } from '@ui5/webcomponents-react';
import { ThemingParameters } from '@ui5/webcomponents-react-base';
const { getConfig, getFeatureToggle } = eurekaMgrs.ConfigManager;
const { useTranslation } = eureka.I18nProvider;
const homeDisabled = true;

// eslint-disable-next-line no-prototype-builtins
const isDisable = (item) => item.hasOwnProperty('disabled') && item.disabled;

const walkSidenav = (propName, propValue, valueName, isDisable, comp, sideNav) => {
  const m = sideNav;
  const nav = comp.config.sidenav[m];
  if (nav[propName] && (propValue.endsWith("/*") ? nav[propName].startsWith(propValue.split('/*')[0]) : nav[propName] === propValue)) {
    return {
      appName: comp.config.app,
      [valueName]: nav[valueName],
      disabled: isDisable(nav),
    };
  } else if (nav.items && Array.isArray(nav.items) && nav.items.length > 0) {
    for (let n = 0; n < nav.items.length; n++) {
      const item = nav.items[n];
      if (item[propName] && (propValue.endsWith("/*") ? item[propName].startsWith(propValue.split('/*')[0]) : item[propName] === propValue)) {
        return {
          appName: comp.config.app,
          [valueName]: item[valueName],
          disabled: isDisable(item),
        };
      }
    }
  } else if (
    propName === 'id' &&
    comp.config.routers.includes(propValue) &&
    !['/kpi-library', '/next-best-actions-config'].includes(propValue)
  ) {
    return {
      appName: comp.config.app,
      [valueName]: nav[valueName],
      disabled: isDisable(nav),
    };
  }
};

const getConfigValueByProp = (config, propName, propValue, valueName) => {
  let retValue;
  for (let i = 0; i < config?.components?.length && !retValue; i++) {
    const comp = config?.components[i];
    for (let m = 0; m < comp?.config?.sidenav?.length; m++) {
       retValue = walkSidenav(propName, propValue, valueName, isDisable, comp, m);
      if (retValue) {
        return retValue;
      }
    }
  }
  return retValue;
};

const checkPermissions = (item, currentUserPermissions) => {
  // eslint-disable-next-line no-prototype-builtins
  if (item.hasOwnProperty('skipPermission') && item.skipPermission === 'true') {
    return true;
  }

  if (item.permissions) {
    const permissions = Array.isArray(item.permissions)
      ? item.permissions.map((p) => p.toUpperCase())
      : [];
    if (item.conjunction && item.conjunction === 'OR') {
      return permissions.length > 0 && permissions.some((p) => currentUserPermissions.includes(p));
    }

    return permissions.length > 0 && permissions.every((p) => currentUserPermissions.includes(p));
  }
  return false;
};

// react-jss style setup
const disabledColor = 'rgba(205, 206, 207, 0.8)';
const seletedColor = '#0854a0';
const enableColor = '#32363a';
const styles = {
  sideNavigation: {
    overflowY: 'auto',
    '& > div': {
      overflow: 'hidden',
    },
    '--_ui5_list_item_title_size': 'var(--sapFontSize)',
  },
  disabled: {
    color: disabledColor,
    backgroundColor: 'transparent !important',
    borderLeft: '4px solid transparent',
    cursor: 'initial',
    '& ui5-icon': {
      color: disabledColor,
    },
    '& span': {
      color: disabledColor,
    },
  },
  enabled: {
    borderLeft: '4px solid transparent',
    // backgroundColor: '#e5f0fa',
    '& ui5-icon': {
      color: enableColor,
    },
    '&[selected=true]': {
      borderLeft: `4px solid ${seletedColor}`,
      '& ui5-icon': {
        color: seletedColor,
      },
      '& span': {
        color: seletedColor,
      },
    },
  },
  customized: {
    '& >span:nth-child(3)': {
      position: 'absolute',
      right: 0,
      height: '48px',
      width: '400px',
      boxSizing: 'border-box',
      '& ui5-icon': {
        right: '13px',
        top: '16px',
        position: 'absolute',
      },
    },
  },
};
const useStyles = createUseStyles(styles);

function SidePanel(props) {
  const [selectedId, setSelectedId] = useState('home');
  const [appName, setAppName] = useState('');
  const { t } = useTranslation();
  const { showMenu } = props.common;
  const classes = useStyles();
  const [currentUserPermissions, setCurrentUserPermissions] = useState(
    getConfig('CurrentUserPermissions') || [],
  );

  const hiddenFeatureFlagArray = hiddenSideNavElements.map((obj) => {
    return {
      componentName: obj.componentName,
      featureFlag: getFeatureToggle(obj.featureFlag),
    };
  });

  useEffect(() => {
    const { config } = props;
    let { pathname } = props.history.location;
    const matchPatch = props.match.path;
    if (pathname !== matchPatch) {
      pathname = matchPatch;
    }

    if (pathname !== '/') {
      const cfg = getConfigValueByProp(config, 'router', pathname, 'id');
      if (cfg && cfg.id) {
        /* istanbul ignore next */
        setAppName(cfg.appName);
        /* istanbul ignore next */
        setSelectedId(cfg.id);
      } else {
        setAppName('');
        setSelectedId('home');
      }
    }
  }, [props]);

  /* istanbul ignore next */
  const onItemClick = (evt) => {
    const { config } = props;
    const selectedId = evt.detail.item.id;

    if (!selectedId) {
      return;
    }
    const testingLngCode = getURLParam(props.history.location.search, 'sap-language');
    const testingLngParam = testingLngCode ? `?sap-language=${testingLngCode}` : '';

    if (selectedId === 'home' && !homeDisabled) {
      setSelectedId('home');
      props.history.push(`/${testingLngParam}`);
    } else {
      const cfg = getConfigValueByProp(config, 'id', selectedId, 'router');
      if (cfg && cfg.router && cfg.router !== '#' && !cfg.disabled) {
        try {
          props.history.push(cfg.router + testingLngParam);
        } catch (e) {
          console.log(e);
        }
      }
    }
  };

  const getListItems = () => {
    const { config } = props;

    let listitems = [];
    if (config && config.components) {
      config.components.forEach((comp) => {
        const { sidenav } = comp.config;
        if (sidenav && Array.isArray(sidenav) && sidenav.length > 0) {
          listitems = listitems.concat(sidenav);
        }
      });
    }

    listitems = listitems
      .filter(
        (i) =>
          i.items?.filter((c) => checkPermissions(c, currentUserPermissions)).length > 0 ||
          checkPermissions(i, currentUserPermissions),
      )
      .sort((a, b) => a.order - b.order);

    hiddenFeatureFlagArray?.forEach((item) => {
      if (listitems) {
        listitems = removeFeatureFlaggedComponent(listitems, item.componentName, item.featureFlag);
      }
    });

    return listitems;
  };

  /* istanbul ignore next */
  const renderNavItems = () =>
    getListItems().map((item, idx) => {
      if (item.items && Array.isArray(item.items) && item.items.length > 0) {
        return (
          <SideNavigationItem
            data-testid={item.id}
            expanded={!!item.items.find((child) => child.id === selectedId)}
            key={item.id}
            className={
              isDisable(item) ? classes.disabled : `${classes.enabled} ${classes.customized}`
            }
            text={t(item.text)}
            icon={item.icon}
            id={item.id}
            title={t(item.text)}
            wholeItemToggleable={true}
          >
            {item.items
              .filter((i) => checkPermissions(i, currentUserPermissions))
              .map((child) => (
                <SideNavigationSubItem
                  data-testid={child.id}
                  selected={child.id === selectedId}
                  key={child.id}
                  className={
                    isDisable(child) ? classes.disabled : `${classes.enabled} ${classes.customized}`
                  }
                  text={t(child.text)}
                  icon={child.icon}
                  id={child.id}
                  data-id={child.id}
                  title={t(child.text)}
                />
              ))}
          </SideNavigationItem>
        );
      } else {
        return (
          <SideNavigationItem
            key={item.id}
            selected={item.id === selectedId}
            className={
              isDisable(item) ? classes.disabled : `${classes.enabled} ${classes.customized}`
            }
            // style={{ backgroundColor: 'red !important' }}
            text={t(item.text)}
            icon={item.icon}
            id={item.id}
            title={t(item.text)}
          />
        );
      }
    });

  const renderMenuItemSelectionIcon = useMemo(() => {
    return (
      <Icon
        style={{
          height: '0.5rem',
          width: '0.5rem',
          marginInlineEnd: '0.3rem',
          color: ThemingParameters.sapShell_Navigation_SelectedColor,
        }}
        name="circle-task-2"
      />
    );
  }, []);

  /* istanbul ignore next */
  const renderListItems = () =>
    getListItems().map((item, pidx) => {
      if (item.items && Array.isArray(item.items) && item.items.length > 0) {
        return (
          <>
            <StandardListItem
              data-testid={item.id}
              data-list-index={pidx}
              key={item.id}
              className={
                isDisable(item) ? classes.disabled : `${classes.enabled} ${classes.customized}`
              }
              text={t(item.text)}
              icon={item.icon}
              id={item.id}
              title={t(item.text)}
              style={{ border: 'none' }}
            >
              <Text style={{ fontWeight: 'bold' }}>{t(item.text)}</Text>
            </StandardListItem>
            {item.items
              .filter((i) => checkPermissions(i, currentUserPermissions))
              .map((child, cidx) => (
                <StandardListItem
                  data-testid={child.id}
                  data-list-index={pidx + '-' + cidx}
                  selected={child.id === selectedId}
                  key={child.id}
                  className={
                    isDisable(child) ? classes.disabled : `${classes.enabled} ${classes.customized}`
                  }
                  text={t(child.text)}
                  icon={child.icon}
                  id={child.id}
                  data-id={child.id}
                  title={t(child.text)}
                  style={{ border: 'none' }}
                >
                  <FlexBox justifyContent={'SpaceBetween'} alignItems={'Center'}>
                    <Text style={{ paddingLeft: '25px' }}>{t(child.text)}</Text>
                    {child.id === selectedId && renderMenuItemSelectionIcon}
                  </FlexBox>
                </StandardListItem>
              ))}
          </>
        );
      } else {
        return (
          <StandardListItem
            key={item.id}
            data-testid={item.id}
            data-list-index={pidx}
            selected={item.id === selectedId}
            className={
              isDisable(item) ? classes.disabled : `${classes.enabled} ${classes.customized}`
            }
            text={t(item.text)}
            icon={item.icon}
            id={item.id}
            title={t(item.text)}
            style={{ border: 'none' }}
          >
            <FlexBox justifyContent={'SpaceBetween'} alignItems={'Center'}>
              <Text style={{ fontWeight: 'bold' }}>{t(item.text)}</Text>
              {item.id === selectedId && renderMenuItemSelectionIcon}
            </FlexBox>
          </StandardListItem>
        );
      }
    });

  return !showMenu ? (
    <SideNavigationObserver
      data-testid="sidePanelList"
      className={classes.sideNavigation}
      collapsed={!showMenu}
      selectedId={selectedId}
      onSelectionChange={onItemClick}
      noIcons={false}
      style={{ height: '100%', fontSize: '14px', width: '400px' }}
    >
      {renderNavItems()}
    </SideNavigationObserver>
  ) : (
    <List
      data-testid="sidePanelList"
      onItemClick={onItemClick}
      style={{
        borderRight: `1px solid ${ThemingParameters.sapList_BorderColor}`,
        backgroundColor: ThemingParameters.sapList_Background,
      }}
    >
      {renderListItems()}
    </List>
  );
}

SidePanel.propTypes = {
  actions: PropTypes.object.isRequired,
  common: PropTypes.object.isRequired,
};
SidePanel.defaultProps = {};

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    common: state.common,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SidePanel);
