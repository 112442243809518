/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { Suspense } from 'react';
import eureka from 'eureka';
import { createUseStyles } from 'react-jss';
import logo from './ErrorBear';
import { Header, SidePanel } from '.';
import { ShellDialog } from '../plugins/dialog.plugin';
import '@ui5/webcomponents-fiori/dist/illustrations/PageNotFound.js';

import { IllustratedMessage, IllustrationMessageType } from '@ui5/webcomponents-react';

const { Spinner } = eureka.components;
const { useTranslation } = eureka.I18nProvider;

export default (rowprops) => {
  const { t } = useTranslation();
  const { props, errorType, errorCode } = rowprops;

  const styles = () => ({
    errorPage: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    errorTitle: {
      fontWeight: 'normal',
      fontSize: '144px',
      lineHeight: '166px',
      color: '#a7a7a7',
      marginBottom: '17px',
      marginTop: '-3rem',
    },
    errorContent: {
      fontSize: '25px',
      lineHeight: '29px',
      color: '#a7a7a7',
    },
    dashboard: {
      color: '#0854A0',
      cursor: 'pointer',
    },
    errorMsg: {
      fontSize: '42px',
      fontWeight: 'bold',
      lineHeight: '48px',
      color: '#fff',
      margin: 0,
    },
    errorMsgWrapper: {
      position: 'absolute',
      top: '225px',
      left: '145px',
      textAlign: 'center',
    },
  });
  const useStyles = createUseStyles(styles);

  const classes = useStyles();

  const errorMapping = {
    500: {
      errorTitle: 'ErrorTips_Oops',
      errorContent: 'ErrorTips_500',
    },
    403: {
      errorTitle: 'ErrorTips_Wait',
      errorContent: 'ErrorTips_403',
    },
    404: {
      errorTitle: 'PageNotFound',
      errorContent: 'ErrorTips_404',
    },
  };

  const errorInstance = errorMapping[errorType];

  return (
    <div className="fd-shell fd-shell--fundamentals">
      <div className="fd-shell__header" style={{ position: 'relative' }}>
        <Suspense fallback={<Spinner />}>
          <Header
            history={props.history}
            config={props.config}
            settings={props.settings}
            eventBus={props.eventBus}
            user={props.user}
          />
        </Suspense>
      </div>
      <div className="fd-shell__app">
        <div className="fd-app">
          <div>
            <Suspense fallback={<Spinner />}>
              <SidePanel history={props.history} match={props.match} config={props.config} />
            </Suspense>
          </div>
          <main className="fd-app__main">
            <div className={classes.errorPage}>
              <IllustratedMessage
                name={IllustrationMessageType.PageNotFound}
                titleText={t(errorInstance.errorTitle)}
                subtitleText={t(errorInstance.errorContent)}
              />
              {errorType === '500' && (
                <p className={classes.errorContent}>{t('ErrorTips_refresh')}</p>
              )}

              {errorType === '500' && (
                <p className={classes.errorContent} style={{ marginTop: '43px', width: '654px' }}>
                  {t('ErrorCode_code')}
                  {errorCode}
                </p>
              )}
            </div>
          </main>
        </div>
      </div>
      <ShellDialog key="Error-page-dialog" />
    </div>
  );
};
