import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';

import Header from './Header';
import eureka from 'eureka';
import SidePanel from './SidePanel';
import { ShellDialog } from '../plugins/dialog.plugin';
import MessageToast from '../plugins/toast.plugin';
import OptionalMessageBox from '../plugins/messagebox.plugin';
import EurekaComponents from 'eureka';

const { Spinner } = EurekaComponents.components;
const { getCurrentLanguage } = eureka.I18nProvider;
export class DefaultLayout extends Component {
  static propTypes = {
    children: PropTypes.node,
    history: PropTypes.object,
    settings: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { showMenu : props.common?.showMenu ? true : false };
}

static getDerivedStateFromProps(props, state) {
  return { showMenu: props.common?.showMenu ? true : false };
}

  render() {
    document.lang = getCurrentLanguage();
    return (
      <div className="fd-shell fd-shell--fundamentals" id="fd-shell">
        <div style={{ background: 'var(--sapShellColor)' }} id="fd-shell-header">
          <Suspense fallback={<Spinner />}>
            <Header
              history={this.props.history}
              config={this.props.config}
              settings={this.props.settings}
              user={this.props.user}
              common={this.props.common}
            />
          </Suspense>
        </div>
        <div className="fd-shell__app">
          <div className="fd-app">
            <div
              className={
                'fd-app__navigation fd-app__navigation--vertical' +
                (this.state.showMenu ? ' show-menu ' : ' hide-menu ')
              }
            >
              <Suspense fallback={<Spinner />}>
                <SidePanel
                  history={this.props.history}
                  match={this.props.match}
                  config={this.props.config}
                />
              </Suspense>
            </div>
            <main className="fd-app__main" id="app-area">
              <Suspense fallback={<Spinner cssClass="page-load-spinner" />}>
                {this.props.children}
              </Suspense>
            </main>
          </div>
        </div>
        <ShellDialog key="default-layout-dialog" />
        <MessageToast key="default-message-toast" />
        {/* <OptionalMessageBox /> */}
        {/* <div className="fd-shell__footer"><span>SAP</span></div> */}
      </div>
    );
  }
}


DefaultLayout.propTypes = {
  common: PropTypes.shape({showMenu:PropTypes.bool}),
};

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    common: state.common,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);
