import React, { useEffect, useRef } from 'react';
import { List, ListMode, CustomListItem } from '@ui5/webcomponents-react';
import eurekaMgrs from '@eureka/ui-managers';
import { ThemingParameters } from '@ui5/webcomponents-react-base';
import { Icon } from '@ui5/webcomponents-react';
import '@ui5/webcomponents-icons/dist/json-imports/Icons.js';

const { getThemeId, getThemeList } = eurekaMgrs.AppearanceManager;
const formItemStyle = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
};

export default function UserAppearance({ data, onDataPropChange, actions, common }) {
  const [currentTheme, setCurrentTheme] = React.useState(getThemeId());

  return (
    <div style={{ ...formItemStyle }}>
      <List
        id="theme-setting"
        data-testid="select-theme-id"
        value={getThemeId()}
        mode={ListMode.SingleSelect}
        onSelectionChange={(evt) => {
          /* istanbul ignore next */
          const themeId = evt.detail?.selectedItems[0]?.dataset.id;
          console.log(`Theme Id is ${themeId}`);
          setCurrentTheme(themeId);
          onDataPropChange(themeId, 'themeId');
        }}
        style={{ width: '98%', height: '330px' }}
      >
        {getThemeList().map((theme, i) => {
          return (
            <CustomListItem
              key={theme.themeId + '-option'}
              data-id={theme.themeId}
              value={theme.themeId}
              selected={getThemeId() === theme.themeId}
              style={{ width: '100%', height: '68px', paddingRight: '2px' }}
              tooltip={theme.displayName}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '100px' }}>
                  <div
                    style={{
                      ...theme.style,
                      width: '2.5rem',
                      height: '2.5rem',
                      borderRadius: '100%',
                      margin: 'auto',
                    }}
                  ></div>
                </div>
                <div
                  data-testid={'select-theme-id-' + i}
                  style={{
                    width: '260px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    lineHeight: '68px',
                    color: ThemingParameters.sapTextColor,
                    paddingRight: '2px',
                  }}
                >
                  {theme.displayName}
                </div>
              </div>
            </CustomListItem>
          );
        })}
      </List>
    </div>
  );
}
