import eureka from 'eureka';
import eurekaMgrs from '@eureka/ui-managers';

const { Spinner } = eureka.components;
const { MessageToast, OptionalMessageBox } = eureka.controls;
const { initI18n, useTranslation, getCurrentLanguage, setLanguage, withTranslation } =
  eureka.I18nProvider;
const { fromUtcToZone, UtcMapping } = eureka.DateManager;
const { eventBus } = eurekaMgrs;
const { axiosCreate } = eureka.HttpWrapper;

const {
  addConfig,
  getConfig,
  setCsrfToken,
  getCsrfToken,
  setLanguage: configManagerSetLanguage,
  getLanguage: configManagerGetLanguage,
  updateFeatureToggle,
  setDebug,
} = eurekaMgrs.ConfigManager;
const { ProxyHelper } = eurekaMgrs.ProxyManager;

export {
  Spinner,
  MessageToast,
  initI18n,
  useTranslation,
  getCurrentLanguage,
  setLanguage,
  withTranslation,
  fromUtcToZone,
  UtcMapping,
  eventBus,
  axiosCreate,
  addConfig,
  getCsrfToken,
  setCsrfToken,
  getConfig,
  OptionalMessageBox,
  configManagerSetLanguage,
  configManagerGetLanguage,
  updateFeatureToggle,
  ProxyHelper,
  setDebug,
};
