import { getRandom } from '../features/common/Utils';
import eurekaMgrs from '@eureka/ui-managers';
const { getThemeList } = eurekaMgrs.AppearanceManager;
export const loadCSS = (dom, assetName, host, name, filePath, hash, callback) => {
  // load css styles
  // console.log(assetName, filePath, hash);

  const buildCssLink = (resolve, reject) => {
    const styleId = `${assetName}-style-${name.toLowerCase()}`;
    const style = dom.getElementById(styleId);
    if (!style) {
      const cssLink = dom.createElement('link');
      cssLink.id = styleId;
      cssLink.rel = 'stylesheet';
      cssLink.type = 'text/css';
      cssLink.crossOrigin = 'anonymous';
      cssLink.href = `${host}${filePath}?random=${getRandom()}`;
      if (hash) {
        cssLink.integrity = hash;
      }
      cssLink.onload = () => resolve();
      cssLink.onerror = (err) => reject(err);
      dom.head.appendChild(cssLink);
    } else {
      resolve();
    }
  };

  return new Promise((resolve, reject) => {
    buildCssLink(resolve, reject);
  });
};

export function getURLParam(url, name) {
  const searchParams = new URLSearchParams(url);
  return searchParams.get(name);
}

// 1Q = en-US-saptrc;
// 2Q = en-US-sappsd;
// 3Q = en-US-x-saprigi;
export const TestingLocales = {
  '1Q': 'en-US-saptrc',
  '2Q': 'en-US-sappsd',
  '3Q': 'en-US-x-saprigi',
};
export const constructPermissions = (rolePermission) => {
  const permissions = [];
  (rolePermission || [])
    .map((r) => r.permissionItem)
    .forEach((p) => {
      p.forEach((o) => {
        o.items.forEach((i) => {
          i.accesses.forEach((access) => {
            const authItem = `${i.authItemValue}.${access.accessName}`.toUpperCase();
            if (!permissions.includes(authItem)) {
              permissions.push(authItem);
            }
          });
        });
      });
    });

  return permissions;
};

/**
 * function to remove a MFE Component from Side-nav list if FF is disabled
 * @param {Array}: input array containing list of components/MFEs
 * @param {String}: name of components/MFE to be shown/hidden
 * @param {Boolean}: feature flag
 * @returns {Array}: output array containing list of components/MFEs to be displayed in side-nav
 * */
/* istanbul ignore next */
export function removeFeatureFlaggedComponent(componentList, componentName, featureFlag) {
  if (!componentName || featureFlag === undefined) {
    return componentList;
  }

  // Remove parent nav item
  if (!featureFlag) {
    componentList = componentList.filter((item) => item.id !== componentName);
  }

  // Removing nested sub items based on feature flag
  if (!featureFlag) {
    componentList = componentList.map((each) => {
      if (each.items) {
        return {
          ...each,
          items: each.items.filter((item) => item.id !== componentName),
        };
      } else {
        return each;
      }
    });
  }

  return componentList;
}

export const THEME_LIST = [
  {
    name: 'sap_horizon',
    displayName: 'SAP Morning Horizon',
    themeId: 'sap_horizon',
    icon: '',
    style: {
      backgroundColor: 'rgb(255, 255, 255)',
      border: '1px solid #758ca4',
      boxShadow: 'inset 0 0 2px #758ca4',
    },
    order: '10',
  },
  {
    name: 'sap_horizon_dark',
    displayName: 'SAP Evening Horizon',
    themeId: 'sap_horizon_dark',
    icon: '',
    style: {
      backgroundColor: 'rgb(29, 45, 63)',
      border: '1px solid #1D2D3E',
      boxShadow: 'inset 0 0 2px #a9b4be',
    },
    order: '20',
  },
  {
    name: 'sap_horizon_hcw',
    displayName: 'SAP High Contrast White',
    themeId: 'sap_horizon_hcw',
    icon: '',
    style: {
      backgroundColor: 'rgb(255, 255, 255)',
      border: '1px solid #000',
    },
    order: '30',
  },
  {
    name: 'sap_horizon_hcb',
    displayName: 'SAP High Contrast Black',
    themeId: 'sap_horizon_hcb',
    icon: '',
    style: {
      backgroundColor: 'rgb(0, 0, 0)',
      border: '1px solid #FFF',
    },
    order: '40',
  },
];

export const getThemeById = (themeId) => {
  return (getThemeList() || []).find((ele) => ele.themeId === themeId);
};
