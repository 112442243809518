import React from 'react';
import { IllustratedMessage, IllustrationMessageType } from '@ui5/webcomponents-react';
import '@ui5/webcomponents-fiori/dist/illustrations/SearchEarth.js';

import EurekaComponents from 'eureka';
const { useTranslation } = EurekaComponents.I18nProvider;

function WelcomeMessage() {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div
        style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <IllustratedMessage
          name={IllustrationMessageType.SearchEarth}
          titleText={t('Welcome_to_SAP_Predictive_Replenishment')}
          subtitleText={t(
            'Seems_you_havent_been_assigned_to_any_roles.Please_contact_the_administrator_to_assign_roles.',
          )}
        />
      </div>
    </React.Fragment>
  );
}

export default WelcomeMessage;
